import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'app/core/http/api.service';
import { environment as env } from '@env/environment';

import { Router } from '@angular/router';
import { CreditHoursService } from '../../../shared/services/credit-hours.service';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// interface ChatMember {
//   name: string;
//   text: string;
// }

@Component({
  selector: 'app-live-test',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css'],
})
export class LiveComponent implements OnInit {
  hall: string = '';
  textaraVal: string = '';
  name: string = '';
  userToken: string;
  result: any;
  speakers: any;
  questions: any;

  live_type: string = 'youtube';
  youtube: string = '';
  youtube1: string = '';
  youtube2: string = '';
  youtube3: string = '';
  youtube4: string = '';
  youtube5: string = '';
  youtube6: string = '';
  youtube7: string = '';
  youtube8: string = '';
  youtube9: string = '';

  vimeo: string = '';
  vimeo1: string = '';
  vimeo2: string = '';
  vimeo3: string = '';

  is_admin = false;

  conference: any;
  conference_id: string = env.selectedEvent;
  @HostListener('window:unload', ['$event'])
  unloadHandler($event: any) {
    console.log('besm allah  11111111111111111');
    // this.check_out();
    console.log($event);
    // $event.returnValue = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: any) {
    // for refresh
    console.log('besm allah  2222222222222222222');
    // this.check_out();
    // $event.returnValue = false;
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  constructor(
    private cookieService: CookieService,
    private apiServerice: ApiService,
    private router: Router,
    private cme: CreditHoursService,
    private activatedRoute: ActivatedRoute,

    public dialog: MatDialog
  ) {
    if (!this.cookieService.check('token')) {
      this.router.navigateByUrl('login');
    }
    this.userToken = this.cookieService.get('token');

    this.is_admin = Boolean(JSON.parse(this.cookieService.get('is_admin')));

    var tem = this.activatedRoute.snapshot.paramMap.get('hall');
    if (tem == null) this.hall = '';
    else this.hall = tem;
  }
  ngOnInit(): void {
    this.doConference();
    this.getQuestions();

    setInterval(() => {
      this.getQuestions();
    }, 10000);

    this.check_in();
    setInterval(() => {
      this.check_out();
    }, 1000 * 60 * 30);
  }

  doConference() {
    this.apiServerice
      .doGet(`conferences/${env.selectedEvent}`)
      .subscribe((res) => {
        // console.log('res', res);
        this.conference = res;
        // console.log('get con', this.conference.live_link);
        this.live_type = this.conference.live_type;
        this.youtube1 = this.conference.youtube1;
        this.youtube2 = this.conference.youtube2;
        this.youtube3 = this.conference.youtube3;
        this.youtube4 = this.conference.youtube4;
        this.youtube5 = this.conference.youtube5;
        this.youtube6 = this.conference.youtube6;
        this.youtube7 = this.conference.youtube7;
        this.youtube8 = this.conference.youtube8;
        this.youtube9 = this.conference.youtube9;
        this.vimeo1 = this.conference.vimeo1;
        this.vimeo2 = this.conference.vimeo2;
        this.vimeo3 = this.conference.vimeo3;

        if (this.hall == 'a') {
          this.youtube = this.conference.youtube2;
        } else {
          this.youtube = this.conference.youtube1;
        }

        // if (this.youtube1) {
        //   this.youtube = this.youtube1;
        // } else if (this.youtube2) {
        //   this.youtube = this.youtube2;
        // } else if (this.youtube3) {
        //   this.youtube = this.youtube3;
        // } else if (this.youtube4) {
        //   this.youtube = this.youtube4;
        // } else if (this.youtube5) {
        //   this.youtube = this.youtube5;
        // } else if (this.youtube6) {
        //   this.youtube = this.youtube6;
        // } else if (this.youtube7) {
        //   this.youtube = this.youtube7;
        // } else if (this.youtube8) {
        //   this.youtube = this.youtube8;
        // } else if (this.youtube9) {
        //   this.youtube = this.youtube9;
        // }

        if (this.hall == 'a') {
          this.vimeo = this.conference.vimeo2;
        } else {
          this.vimeo = this.conference.vimeo1;
        }

        // if (this.vimeo1) {
        //   this.vimeo = this.vimeo1;
        // } else if (this.vimeo2) {
        //   this.vimeo = this.vimeo2;
        // } else if (this.vimeo3) {
        //   this.vimeo = this.vimeo3;
        // }
      });
  }
  add_ques(form: any) {
    // const ca: ChatMember = { name: this.name, text: this.textaraVal };
    // this.todos.push(ca);

    form.value.token = this.userToken;

    this.apiServerice
      .doPost(`questions/${env.selectedEvent}`, form.value)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        // console.log('doPost', res);

        this.result = res;
        if (this.result.status == 'success') {
        }
        // this.status = res.status;
        // this.message = res.message;
      });

    this.textaraVal = '';
  }

  doSpeakers_old() {
    // console.log('Besm allah ');
    // console.log(`conferences/${this.conference.id}`);
    this.apiServerice
      .doGet(`conferences/${env.selectedEvent}/roles/2/members`)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        // console.log('res', res);
        this.speakers = res;
      });
  }

  check_in() {
    this.cme.check_in('');
  }
  check_out() {
    this.cme.check_out('No');
  }

  goTo(location: string): void {
    window.location.hash = '';
    window.location.hash = location;

    // if (history.pushState) {
    //   history.pushState(null, '', location);
    // } else {
    //   window.location.hash = location;
    // }
    console.log('Besm allah ');
  }

  getQuestions() {
    // console.log('Besm allah ');
    // console.log(`conferences/${this.conference.id}`);
    // ${this.hall}
    this.apiServerice
      .doGet(`questions/${env.selectedEvent}`)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        // console.log('res', res);
        this.questions = res;
      });
  }
  openDialog(d_name: string, d_type = 'image') {
    const dialogRef = this.dialog.open(DialogAddQuestion, {
      data: { name: d_name, type: d_type },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogPoll() {
    const dialogRef = this.dialog.open(DialogPoll);

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
}
@Component({
  selector: 'dialog-add-question',
  templateUrl: 'dialog-add-question.html',
})
export class DialogAddQuestion {
  name = 'in Dialog';
  type = 'image';
  // link = '';
  hall: string = '';
  textaraVal: string = '';

  userToken: string;
  conference_id: string = env.selectedEvent;
  result: any;
  speakers: any;

  linkVedio = 'https://player.vimeo.com/video/';
  constructor(
    private apiServerice: ApiService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; type: string }
  ) {
    this.name = data.name;
    this.type = data.type;

    this.doSpeakers();

    this.userToken = this.cookieService.get('token');

    var tem = this.activatedRoute.snapshot.paramMap.get('hall');
    if (tem == null) this.hall = '';
    else this.hall = tem;
    // this.link = 'https://www.youtube.com/embed/' + this.name;
  }
  doSpeakers() {
    this.apiServerice
      .doGet(`conferences/${env.selectedEvent}/today_agenda_speakers`)
      .subscribe((res) => {
        // console.log('today_agenda_speakers', res);
        this.speakers = res;
      });
  }
  add_ques(form: any) {
    // const ca: ChatMember = { name: this.name, text: this.textaraVal };
    // this.todos.push(ca);
    // if (!this.textaraVal) {
    //   return;
    // }
    form.value.token = this.userToken;

    this.apiServerice
      .doPost(`questions/${env.selectedEvent}`, form.value)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        // console.log('doPost', res);

        this.result = res;
        if (this.result.status == 'success') {
        }
        // this.status = res.status;
        // this.message = res.message;
      });

    this.textaraVal = '';
  }
}
@Component({
  selector: 'dialog-poll',
  templateUrl: 'dialog-poll.html',
})
export class DialogPoll {
  popupshow = false;
  updatedData = false;
  questions: any;

  answers: any;
  num_questions = 0;
  result: any;
  server_result = false;
  conference_id: string = env.selectedEvent;
  userToken: string;
  pollValid = true ;
  constructor(
    private apiServerice: ApiService,
    private cookieService: CookieService,
    private router: Router
  ) {
    if (!this.cookieService.check('token')) {
      this.router.navigateByUrl('login');
    }
    this.userToken = this.cookieService.get('token');
  }

  ngOnInit(): void {
    this.getVote();
  }
  getVote() {
    this.apiServerice.doGet(`votes/${env.selectedEvent}`).subscribe((res) => {
      // console.log('votes', res);
      this.questions = res;
      this.num_questions = this.questions.length;

      if (this.num_questions > 0) {
        // console.log('more than one ');
        this.updatedData = true;
       
      } else {
        // console.log('less than one');
      }
    });
  }
  refresh() {
    this.server_result = false;
    this.getVote();
  }
 
  onSummit(form:any) {
    form.value.token = this.userToken;
    console.log('form.value gad', form.value);
    // form.patchValue({ rule_id: 1, conference_id: 1 });
if(form.value.answer_id == ''){
this.pollValid =false;
  return;
}
    if (form.valid) {
      // console.log('link', `conferences/${env.selectedEvent}/register`);
      this.apiServerice
        .doPost(`votes/${env.selectedEvent}`, form.value)
        .subscribe((res) => {
          console.log('Besm allah Alrahman');
          console.log('doPost', res);
          this.result = res;
          if (this.result.status == 'success') {
            this.server_result = true;
            this.updatedData = false; 
            // this.router.navigateByUrl(`registersuccess`);
            // this.router.navigateByUrl(`checkout/${user_id}`);
            // form.reset();
          }
        });
    } else {
      alert('Form not valid');
    }
  }
}