<mat-dialog-content class="mat-typography">
  <!-- <div id="questions" class="questionTab mb-3 text-center">
    Polls List
  </div> -->

  <div *ngIf="!updatedData && !server_result" class="toast-body">
    <br />
    There are no active polls.
    <br /><br />
  </div>
  <div *ngIf="server_result" class="toast-body">
    <br />
    <div class="alert alert-success" role="alert">Successfully submitted.</div>

    <br /><br />
  </div>
  <div *ngIf="updatedData" class="toast-body">
    <div class="form-group">
      <form #f="ngForm" (ngSubmit)="onSummit(f)">
        <div *ngFor="let ques of questions">
          <strong>{{ ques.question }} </strong><br /><br />
          <input type="hidden" name="question_id" ngModel="{{ ques.id }}" />
          <!--Start Answer form-->

          <div *ngFor="let ans of ques.answers" class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="answer_id"
              required
              ngModel
              value="{{ ans.id }}"
              id="flexRadioDefault{{ ans.id }}"
            />
            <label class="form-check-label" for="flexRadioDefault{{ ans.id }}">
              {{ ans.value }}
            </label>
          </div>
          <div *ngIf="pollValid == false" class="toast-body">
            <br />
            <div class="alert alert-danger" role="alert">Please choose answer.</div>
        
            
          </div>
          <!--End Answer form-->
        </div>

        <!--End Question-->
        <div class="toast-footer" style="text-align: right">
          <br />
          <input
            type="hidden"
            name="conference_id"
            ngModel="{{ conference_id }}"
          />

          <input type="hidden" name="token" ngModel="1" />
          <button type="submit" class="btn btn-secondary btn-sm">Submit</button>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end" class="p-0">
  <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions> -->
