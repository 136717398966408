import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { environment as env } from '@env/environment';
import { ApiService } from 'app/core/http/api.service';
import { HeaderComponent } from 'app/layout/header/header.component';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-poll-result',
  templateUrl: './poll-result.component.html',
  styleUrls: ['./poll-result.component.scss'],
})
export class PollResultComponent implements OnInit {
  results: any;
  chart: any;
  question='';
  labelArray=[]as  any;
  DataArray=[]as  any;;
  constructor(private apiServerice: ApiService,private elementRef: ElementRef) { 
  }
  ngOnInit(): void {
    this.doGet();
    //this.chart = document.getElementById('myFirstChart');
    // this.chart = this.elementRef.nativeElement.querySelector(`#myFirstChart`);
    this.chart = this.elementRef.nativeElement.querySelector(`#myFirstChart`);
    Chart.register(...registerables);
   
  }
  loadChart() {
    // let htmlRef = this.elementRef.nativeElement.querySelector(`#myFirstChart`);
    // const labels = ['1','2','1','2','1','2','1'];
    // const labels = Utils.months({count: 7});
    new Chart(this.chart,  {
      type: 'bar',
      data: {
       // labels: ["Africa", "Asia", "Europe", "Latin America", "North America", ],
        labels: this.labelArray,
        datasets: [{ 
         
          barPercentage: 0.9,
          barThickness: 15,
          label: this.question,
          data: this.DataArray,
          borderColor: [],
          backgroundColor: [
          '#f67019',
          '#4dc9f6',
          '#f53794',
          '#537bc4',
          '#acc236',
          '#166a8f',
          '#00a950',
          '#58595b',
          '#8549ba'],
        }],
    
    },
      options:{
    
        indexAxis:'y',
        scales: {
          
          y: {
            
            beginAtZero: true
          },
        
      }
      }
    });
  }
  doGet() {
    // console.log('Besm allah ');
    // console.log(`conferences/${this.conference.id}`);
    this.apiServerice
      .doGet(`votesresult/${env.selectedEvent} `)
      .subscribe((res: any) => {
       
        this.results = res;

        console.log('res=',res);
        this.question = this.results[0].question;
        this.results[0].results.forEach((one: any) => {
          console.log('one=',one.value);
          this.labelArray.push(one.name);
          this.DataArray.push(one.value);
          
        });
        console.log('this.labelArray=',this.labelArray);
        console.log('this.DataArray=',this.DataArray);
        this.loadChart();
      });
  }
}
