<!-- ts schedule start-->
<section class="ts-schedule">
  <app-header></app-header>
  <div class="container pt-5">
    <!-- <div class="row pt-5">
      <div class="col-12">
        <img src="./assets/img/top.png" class="blob-left img-fluid" />
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-8 text-center mx-auto">
        <h2 class="section-title">
          <!-- <span>Schedule Details</span> -->
          Registration
        </h2>
      </div>
      <!-- col end-->
    </div>

    <!-- row end-->
    <div class="row px-2 py-5">
      <form #f="ngForm" (ngSubmit)="onSummit(f)">
        <div class="row" id="content">
          <div id="left_side" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group mb-3">
              <label class="form-label" for="Title"
                >Title <span class="red">*</span></label
              >
              <select
                name="title"
                ngModel
                #title="ngModel"
                class="form-control"
                id="Title"
                required
              >
                <option value="">Select</option>
                <option value="Dr.">Dr.</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
              </select>

              <div
                *ngIf="
                  (title.value == '' && title.dirty) ||
                  (f.submitted && title.value == '')
                "
                class="alert alert-danger"
              >
                Title is required
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="name"
                >First name <span class="red">*</span></label
              >
              <input
                name="name"
                type="text"
                ngModel
                #name="ngModel"
                class="form-control"
                id="name"
                required
                minlength="3"
              />
              <div
                *ngIf="
                  (name.invalid && name.dirty) || (f.submitted && name.invalid)
                "
              >
                <div *ngIf="name.errors!.required" class="alert alert-danger">
                  First name is required
                </div>

                <div *ngIf="name.errors!.minlength" class="alert alert-danger">
                  First name minimum length must be 3 characters
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="lname"
                >Last name <span class="red">*</span></label
              >
              <input
                name="lname"
                type="text"
                ngModel
                #lname="ngModel"
                class="form-control"
                id="lname"
                required
                minlength="3"
              />
              <div
                *ngIf="
                  (lname.invalid && lname.dirty) ||
                  (f.submitted && lname.invalid)
                "
              >
                <div *ngIf="lname.errors!.required" class="alert alert-danger">
                  Last name is required
                </div>

                <div *ngIf="lname.errors!.minlength" class="alert alert-danger">
                  Last name minimum length must be 3 characters
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="Email"
                >Email <span class="red">*</span></label
              >
              <input
                name="email"
                ngModel
                #email="ngModel"
                type="email"
                class="form-control"
                id="Email"
                required
                email
                [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
              />
              <div
                *ngIf="
                  (email.invalid && email.dirty) ||
                  (f.submitted && email.invalid)
                "
                class="alert alert-danger"
              >
                <div *ngIf="email.errors!.required">Email is required</div>
                <div *ngIf="email.errors!.email">
                  Email must be a valid email address
                </div>
              </div>
            </div>
            <!-- <div class="form-group mb-3">
              <label class="form-label" for="Mobile"
                >Mobile <span class="red">*</span></label
              >
              <input
                name="mobile"
                ngModel
                #mobile="ngModel"
                type="tel"
                
                class="form-control"
                id="Mobile"
                [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }"
              />
              required
              <div
                *ngIf="
                  (mobile.invalid && mobile.dirty) ||
                  (f.submitted && mobile.invalid)
                "
                class="alert alert-danger"
              >
                Mobile is required
              </div>
            </div> -->
            <div style="display: none" class="form-group mb-3">
              <label class="form-label" for="Specialty"
                >Specialty<span class="red">*</span></label
              >
              <select
                name="specialty_id"
                ngModel
                #specialty_id="ngModel"
                class="form-control"
                id="Specialty"
              >
                <!-- required -->
                <option value="">-- Select --</option>
                <ng-container *ngFor="let spec of specialties">
                  <option value="{{ spec.id }}">{{ spec.name }}</option>
                  <!-- <ng-template *ngIf="spec.subs.length > 0">
                    <optgroup value="" label="">
                      <option *ngFor="let sub of spec.subs" value="{{ sub.id }}">{{
                        sub.name
                      }}</option>
                    </optgroup>
                  </ng-template> -->
                </ng-container>
              </select>
              <div
                *ngIf="
                  (specialty_id.value == '' && specialty_id.dirty) ||
                  (f.submitted && specialty_id.value == '')
                "
                class="alert alert-danger"
              >
                Specialty is required
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="Institute">Workplace<span class="red">*</span></label>
              <input
                name="institute"
                ngModel
                type="text"
                class="form-control"
                id="Institute"
                required
              />
          

            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="Country"
                >Country <span class="red">*</span></label
              >
              <select
                name="country_id"
                ngModel
                #country_id="ngModel"
                class="form-control"
                id="Country"
                #mySelect
          
              >
                <option value="">-- Select --</option>
                <option value="221">UAE</option>
                <option value="114">Kuwait</option>
                <option value="161">Oman</option>
                <option value="17">Bahrain</option>
                <option value="173">Qatar</option>
              </select>
              <div
                *ngIf="
                  (country_id.value == '' && country_id.dirty) ||
                  (f.submitted && country_id.value == '')
                "
                class="alert alert-danger"
              >
                Country is required
              </div>
            </div>
             <!-- 
            <div class="form-group mb-3">
              <label class="form-label" for="City"
                >City <span class="red">*</span></label
              >
              <select
                name="city_id"
                ngModel
                #city_id="ngModel"
                class="form-control"
                id="City"
                required
              >
                <option value="">-- Select --</option>

                <option *ngFor="let cit of cities" value="{{ cit.id }}">
                  {{ cit.name }}
                </option>
              </select>
              <div
                *ngIf="
                  (city_id.value == '' && city_id.dirty) ||
                  (f.submitted && city_id.value == '')
                "
                class="alert alert-danger"
              >
                City is required
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="Address">Address</label>
              <input
                name="address"
                ngModel
                ngModeltype="text"
                class="form-control"
                id="Address"
              />
            </div>-->

            <div style="display: none" class="form-group mb-3">
              <!-- <label class="form-label"  for="Package"
                >Registration <span class="red">*</span></label
              > -->
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr class="table-primary">
                      <th scope="col" colspan="3">
                        Registration <span class="red">*</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let pack of packages;
                        index as i;
                        first as isFirst
                      "
                    >
                      <td scope="row">
                        <strong>{{ pack.name }}</strong> <br />{{ pack.desc }}
                      </td>

                      <td style="width: 20%">
                        <!-- <div *ngIf="isFirst" class="alert alert-danger">
                          checked
                        </div> -->
                        <input
                          type="radio"
                          value="{{ pack.id }}"
                          name="package_id"
                          [checked]="package_id === pack.id"
                          (change)="selectPackage()"
                          (change)="package_id = $event.target.value"
                        />

                        <!--
                           
                          id="package{{ i }}"
                          name="package_id"
                          
                          [(ngModel)]="package_id"
                          
                          (change)="selectPackage($event)" -->
                        <!-- [attr.checked]="pack.id == package_id" -->
                        <!-- [(ngModel)]="conference.packages[i].selected" -->
                        <label for="package{{ i }}"
                          >&nbsp;
                          {{ pack.fees }}
                          {{ conference.currency }}</label
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- {{ package_id }} -->
                <div
                  *ngIf="f.submitted && package_id == null"
                  class="alert alert-danger"
                >
                  Registration is required
                </div>
              </div>
            </div>
            <div *ngIf="workshops_num > 0" class="form-group mb-3">
              <!-- <label for="Package"
                >Registration <span class="red">*</span></label
              > -->
              <div class="table-responsive">
                <input type="hidden" name="w_ids[]" />
                <table class="table table-striped">
                  <thead>
                    <tr class="table-primary">
                      <th scope="col" colspan="3">Workshops</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let work of workshops;
                        index as i;
                        first as isFirst
                      "
                    >
                      <th scope="row">{{ work.title }}</th>

                      <td>
                        <!-- <div *ngIf="isFirst" class="alert alert-danger">
                          checked
                        </div> -->
                        <input
                          type="checkbox"
                          value="{{ work.id }}"
                          name="workshop_id"
                          id="workshop{{ i }}"
                          (change)="changeWorkshop($event)"
                        />
                        <!--
                          (change)="workshop_id = $event.target.value" 
                          
                         [checked]="workshop_id === work.id"
                          (change)="selectPackage()"
                          name="package_id"
                          
                          [(ngModel)]="package_id"
                          
                          (change)="selectPackage($event)" -->
                        <!-- [attr.checked]="pack.id == package_id" -->
                        <!-- [(ngModel)]="conference.packages[i].selected" -->
                        <label for="workshop{{ i }}"
                          >&nbsp;
                          {{ work.fees }}
                          {{ conference.currency }}</label
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- {{ package_id }} -->
                <div
                  *ngIf="f.submitted && package_id == null"
                  class="alert alert-danger"
                >
                  Registration is required
                </div>
              </div>
            </div>
            <div style="display: none" class="form-group mb-3">
              <label class="form-label" for="Address"
                >Special Requirements</label
              >
              <textarea
                name="special_requirement"
                ngModel
                ngModeltype="text"
                placeholder="Special Requirements"
                class="form-control"
              ></textarea>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="answer1"
                >Attendee Profile <span class="red">*</span></label
              >
              <select
                name="answer1"
                ngModel
                #answer1="ngModel"
                class="form-control"
                id="answer1"
                required
              >
                <option value="">-- Select --</option>
                <option value="IM">Internal Medicine</option>
                <option value="Family Medicine">Family Medicine</option>
                <option value="Pediatrician">Pediatrician</option>
                <option value="GP">G.P</option>
                <option value="GSK staff">GSK Staff</option>
          
              </select>
              <div
                *ngIf="
                  (answer1.value == '' && answer1.dirty) ||
                  (f.submitted && answer1.value == '')
                "
                class="alert alert-danger"
              >
                Attendee Profile is required
              </div>
            </div>
            <div class="form-group form-check mb-3">
              <input
           name="answer2"
           ngModel
           #answer2="ngModel"
           type="checkbox"
           class="form-check-input"
           value="Yes"
           id="data_consent"
           required
         />

              <label class="form-check-label" for="answer2">
                <span class="red">*</span> I confirm that I'm a healthcare provider, invited to this virtual event strictly as the healthcare professional, 
                resident of Bahrain, Kuwait, Oman, Qatar, UAE, who is authorized and licensed to prescribe and/or handle and/or 
                perform clinical practice in this country.
              </label>
              <div
                *ngIf="
                  (answer2.value == '' && answer2.dirty) ||
                  (f.submitted && answer1.value == '')
                "
                class="alert alert-danger"
              >
                This field is mandatory!
              </div>
              
            </div>

            <div class="form-group form-check mb-3"
           ><input
           name="answer3"
           ngModel
           #answer3="ngModel"
           type="checkbox"
           class="form-check-input"
           value="Yes"
           id="mail_list"
         
         />
              <label class="form-check-label" for="answer1"
                >(Optional) You agree to receive Scientific & Promotional content
                or survey from GSK or GSK's 3rd party through different multi-channels 
                including mobile, email and telephone. 
              </label>
              
            </div>
            <div class="form-group form-check mb-3">
              <input
           name="answer4"
           ngModel
           #answer4="ngModel"
           type="checkbox"
           class="form-check-input"
           value="Yes"
           id="terms_conditions"
           required
         />

              <label class="form-check-label" for="answer4">
                <span class="red">*</span> I have read and agree to the terms under <a href ='https://terms.gsk.com/en-xg/pharmaceuticals/default/'>GSK's Privacy Statement.</a>
              </label>
              <div
                *ngIf="
                  (answer4.value == '' && answer4.dirty) ||
                  (f.submitted && answer1.value == '')
                "
                class="alert alert-danger"
              >
                This field is mandatory!
              </div>
              
            </div>
            
           

            <!-- <div class="form-group mb-3 form-check">
              <input
                name="mail_list"
                ngModel
                type="checkbox"
                class="form-check-input"
                value="Yes"
                id="maillist"
              />
              <label class="form-check-label" for="maillist"
                >I accept to receive email notifications about future
                events.</label
              >
            </div> -->
            <!-- {{ f.value | json }} -->
            <!-- {{ conference | json }} -->
          </div>

          <div
            id="right_side"
            class="d-none col-xs-12 col-sm-12 col-md-4 col-lg-4 pt-4"
          >
            <div class="sticky" id="order_sum">
              <h4 class="summary">Order Summary</h4>
              <div *ngIf="package_id != null">
                <h4>Registration</h4>
                <hr class="line" />
                <div class="align">
                  {{ regName }}
                  <!-- packages[package_id].name  -->
                </div>
                <span class="price-label">Price: </span>
                <span class="price"> {{ regPrice }} {{ currency }}</span>
              </div>

              <hr class="line" />
              <span class="price-label">Subtotal: </span>

              <span class="price"> {{ subTotal }} {{ currency }}</span>
              <br />
              <hr class="line" />
              <div class="subtotal">
                Online Payment Fees <span class="price">+3% </span>
              </div>

              <hr class="line" />
              <div class="total-banner">
                <span class="price-label">Total: </span>

                <span class="price">{{ total }} {{ currency }}</span>
              </div>
              <div class="coupon-div">
                <a
                  class="coupon-link"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  (click)="openDialog()"
                >
                  Have a coupon code?
                </a>
              </div>
            </div>
            <!--div style="padding: 14px">Please note that we are currently accepting only wire transfer & cash deposit as online payment is still under process.</div-->
            <br />

            <!--old footer--->
            <div class="row"></div>
          </div>

          <!-- #END the order summry container -->
        </div>

        <input type="hidden" name="rule_id" ngModel="1" />
        <input type="hidden" name="city_id" ngModel="1" />
        <input
          type="hidden"
          name="conference_id"
          ngModel="{{ conference_id }}"
        />
        <div class="w-100 text-center">
          <br />
          <button type="submit" class="w-100 btn btn-lg btn-primary" type="submit">Submit</button>
        </div>
      </form>
      <!-- {{ f.value | json }} -->
      <!-- Include the PayPal JavaScript SDK -->

      <!-- Set up a container element for the button -->
     
        <div class="w-100 text-center">
          <a [routerLink]="['/']" class="w-100 btn btn-lg mt-2 new-req-btn"
          style="color: #fffafa">Back to Home</a>
        
      </div>
    </div>
    
    <br />
    <div
      *ngIf="f.submitted && result?.status == 'success'"
      class="row alert alert-success"
    >
      {{ result.message }}
    </div>
    <div
      *ngIf="f.submitted && result?.status == 'error'"
      class="row alert alert-danger"
    >
      {{ result.message }}
    </div>
  </div>
  <!-- container end-->
  <div class="container pt-5 table-responsive" >
    <table class="table">
      <tbody>
        <tr>
          <td>


Please <a href="https://medicalevents.tv/cm_ups/attachments/2022/08/16/VOFPyjsiEAt7gQcRlHK6H02U4zNG7RyYmEV7TANp.pdf">click here</a> for prescribing information.

For more information, please refer to the prescribing information or contact GlaxoSmithKline via <a href="mailto:Gcc.Medinfo@gsk.com">Gcc.Medinfo@gsk.com</a>. 

To report Adverse Event/s associated with the use of GSK product/s, please get in touch with us via <a href="mailto:gulf.safety@gsk.com">gulf.safety@gsk.com</a>. 

All Quality complaints should be reported to the LOC Quality department mailbox box <a href="mailto:Gulf-KSA.Product-Complaints@gsk.com">Gulf-KSA.Product-Complaints@gsk.com</a>.

This message is for the use of a Registered Medical Practitioner or a Hospital or a Laboratory only. 

You are receiving this email as we have your details as a healthcare professional. Email programs can consider these messages as junk mail and may automatically put them in your bulk or junk folder. To ensure the emails you've requested delivered to your inbox, add our email address to your address book. Please do not reply to this message as these emails are not monitored.

About your privacy: GSK may monitor our technology tools and services (including email, phone, and other communications sent to and from GSK) in order to maintain the security of systems, and to protect our staff, customers, and business partners from cyber threats and loss of information. These monitoring activities include checks for offensive materials, viruses, and other malignant code, and unauthorized or unlawful activity. 

GSK monitoring is conducted with appropriate confidentiality controls and in accordance with local laws. You can learn about the information that we may process about you, and how we use your information, <a href="https://assets.gskstatic.com/pharma/GSKpro/Gulf/hcp-global-notice-11-aug-20.pdf">here</a> <br>
You can also view our <a href="https://www.gsk.com/en-gb/locations/united-kingdom/">terms of use</a>.
<br>

Trademarks are owned by or licensed to the GSK group of companies.
<br>
Copyright © GlaxoSmithKline 2020. All rights reserved.
<br>
The following contact information must be used by healthcare professionals from UAE in addition to the GSK safety reporting email:
          </td>
        </tr>
      </tbody>
    </table>
<div class="row align-items-center">
  <div class="col">
    <table class="table " > 
      <thead >
        <tr>
          <th scope="col" >
            Call for Reporting
  
          </th >
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Department of Pharmacovigilance & Drug Information
Directorate General of Pharmaceutical Affairs & Drug Control
<br>
Ministry of Health, Sultanate of Oman
<br>
Phone Nos. 0096822357687 / 0096822357686
Fax: 0096822358489
<br>
Email: pharma-vigil@moh.gov.om 
<br>
Website: www.moh.gov.om

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col">
    <table class="table " dir="rtl" > 
      <thead >
        <tr>
          <th scope="col" >
            الاتصال للإبلاغ
  
          </th >
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            دائرة المعلومات الدوائية
المديرية العامة للصيدلة و الإدارة العامة
وزارة الصحة، سلطنة عمان
<br>
هاتف: 0096822357687 / &nbsp; 0096822357686
<br>
فاكس: 0096822358489
<br>
البريد الالكتروني:  pharma-vigil@moh.gov.om
  <br>
الموقع الالكتروني: www.moh.gov.om 
<br>
<br>


          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
 
</div>
    
  </div>

  <div class="footer m">
    <img src="./assets/img/bottom.png" class="blob-bright img-fluid" />
  </div>
</section>
<!-- ts schedule end-->
