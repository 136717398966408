

<!-- ts schedule start-->
<section class="ts-schedule">
    <div class="container">

      <!-- <div class="row">
        <div class="col-lg-8 mx-auto">
          <h2 class="section-title">
         
            Polls result
          </h2>
        </div>
        
      </div> -->
     
      
      <!-- row end-->
      <div class="row" >
        <div class="col-lg-12 text-center">
          <br />
         
          <h3>{{ question }}</h3>
         <div class="chart-container">
          <canvas id="myFirstChart"  ></canvas>
        </div>
            

            <!-- <ngx-charts-bar-vertical
              [view]="[900, 400]"
              [results]="res.results"
              [xAxisLabel]="'Speakers'"
              [legendTitle]="'Speakers'"
              [yAxisLabel]="'Vote'"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxis]="true"
              [yAxis]="true"
              [gradient]="true"
            >
            </ngx-charts-bar-vertical> -->
          </div>
        </div>
      
    </div>
    <!-- container end-->
  </section>
  <!-- ts schedule end-->
